<template>
  <div class="pie-legend" v-if="isFromJobStatus">
    <div
      class="legend"
      v-for="legend in data"
      :key="legend.name"
      style="cursor: pointer"
      @click="$router.push(`/job-candidates-status?${legend.urlToJobStatus}`)"
    >
      <p class="legend-count m-0" :style="`color: ${getColor(legend.name)}`">
        {{ legend.count }}
      </p>
      <p v-if="legend.name=='agencymatched'" class="label">{{'Manual Matched'}}</p>
      <p v-else class="label">{{ legend.displayName }}</p>
    </div>
  </div>
  <div class="pie-legend" v-else>
    <div class="legend" v-for="legend in data" :key="legend.name">
      <p class="legend-count m-0" :style="`color: ${getColor(legend.name)}`">
        {{ legend.count }}
      </p>
      <!-- {{getColor(legend.name)}} -->
      <p  class="label">{{ legend.displayName }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    isFromJobStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getColor"]),
  },
};
</script>
<style lang="scss" scoped>
.pie-legend {
  display: flex;
  flex-flow: row wrap;
  .legend {    
    width: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px 0;
    .legend-count {
      font-weight: 600;
      font-size: 16px;
    }
    .label {
      margin: 0;
      white-space: nowrap;
      font-size: 11px;
    }
  } 
  @media only screen and (max-width: 320px) {
    .pie-legend { 
      .legend {   
        width: 33%;
      }
    }
  }

}
</style>
